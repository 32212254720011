@tailwind base;
@tailwind components;
@tailwind utilities;

$font-main: 'Nunito', sans-serif;

@mixin header-h1() {
  @media (max-width:780px) {
    font-size: 3.125rem;
    line-height: 4rem;
  }
  @media (max-width:600x) {
    font-size: 2.625rem;
    line-height: 4rem;
  }
  font-family: 'Open Sans', sans-serif;
  font-size: 4.125rem;
  line-height: 5.775rem;
  font-weight: 400;
}
@mixin header-h2() {
  font-family: $font-main;
  font-size: 3.75rem;
  line-height: 5.25rem;
  font-weight: 200;
  @media (max-width: 600px) {
    font-size: 2.5rem;
  }
}
@mixin header-h3() {
  @media (max-width:1000px) {
    font-size: 1.25rem;
  }
  font-family: $font-main;
  font-size: 1.375rem;
  line-height: 1.925rem;
  font-weight: 600;
}
@mixin text-regular() {
  font-family: $font-main;
  font-size: 1.125rem;
  line-height: 1.575rem;
  font-weight: 400;
}
@mixin text-regular-bold() {
  font-family: $font-main;
  font-size: 1.125rem;
  line-height: 1.575rem;
  font-weight: 600;
}
@mixin title-regular() {
  @media (max-width:780px) {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  font-family: $font-main;
  font-size: 1.125rem;
  line-height: 1.575rem;
  font-weight: 400;
}
@mixin text-small() {
  font-family: $font-main;
  font-size: 0.938rem;
  line-height: 1.313rem;
  font-weight: 400;
}

@mixin nunito() {
  font-family: $font-main;
  font-size: 0.875rem;
  line-height: 1.225rem;
  font-weight: 400;
}

@mixin nunito-r-13() {
  font-family: $font-main;
  font-size: 0.813rem;
  line-height: 1.138rem;
  font-weight: 400;
}

@mixin inter() {
  font-family: 'Inter', sans-serif;
  font-size: 1.375rem;
  line-height: 1.925rem;
  font-weight: 200;
}

@layer base {
  html {
    font-size: 14px;
    font-family: $font-main;
    scroll-behavior: smooth;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  @media (min-width:780px) {
    html {
      font-size:14px
    }
  }

  @media (min-width:1200px) {
    html {
      font-size:16px
    }
  }

  body {
    width: 100%;
    overflow: hidden;
  }

  #msg-toast {
    width: 340px;
    box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.75);
 }

  .font-header-h1 {
    @include header-h1();
  }

  .font-header-h2 {
    @include header-h2();
  }

  .font-header-h3 {
    @include header-h3();
  }

  .font-text-regular {
    @include text-regular();
  }

  .font-text-regular-bold {
    @include text-regular-bold();
  }

  .font-title-regular {
    @include title-regular();
  }

  .font-text-small {
    @include text-small();
  }

  .font-nunito {
    @include nunito();
  }

  .font-nunito-r-13 {
    @include nunito-r-13();
  }

  .font-text-small {
    @include text-small();
  }

  .font-inter {
    @include inter();
  }

  h2 {
    @include header-h2();
  }

  .spadding {
    padding: 0 2.625rem;
    @media (max-width:1200px) {
      padding: 0 2.25rem
    }
  }

  /* <Layout> */
  .logo {
    background-image: url("../../public/img/logo.svg");
    background-repeat: no-repeat;
    width: 230px;
    position: absolute;
    height: 50px;
    top: 12px;
    left: 52px;
    transition: all .2s;
  }
  @media (max-width:1000px) {
    .logo {
      background-image: url("../../public/img/logo_mobile.png");
      height: 50px;
      width: 70px;
      top: 6px;
      left: 25px;
      background-position: 0px 10px;
    }
  }

  @media (max-width:780px) {
    .logo {
      top: 1px;
      background-size: 80%;
    }  
  }

  .logo-mini {
    background-repeat: no-repeat;
    transition: all .2s;
    background-image: url("../../public/img/logo-mini.svg");
    width: 4rem;
    background-size: contain;
    margin: 0.2rem;
  }

  .menu-button {
    width: 100%;
    display: flex;

    .button {
      margin-top: -11px;
      box-sizing: border-box;
      height: 36px;
      cursor: pointer;
      z-index: 2;

      svg {
        stroke: #fff;
        transition: .2s;

        g {
          &:first-child {
            opacity: 1;
            transition: opacity 0s .2s;

            line {
              transition: transform .2s .2s;
              transform: translateY(0px);
            }
          }

          &:last-child {
            opacity: 0;
            transition: opacity 0s .2s;

            line {
              transition: transform .2s;
              transform: rotate(0deg);
              transform-origin: center;
            }
          }
        }
      }

      &.-menu-open svg {
        stroke: #fff;

        g {
          &:first-child {
            opacity: 0;

            line {
              transition: transform .2s;

              &:first-child {
                transform: translateY(7px);
              }

              &:last-child {
                transform: translateY(-7px);
              }
            }
          }

          &:last-child {
            opacity: 1;

            line {
              transition: transform .2s .2s;

              &:first-child {
                transform: rotate(45deg);
              }

              &:last-child {
                transform: rotate(-45deg);
              }
            }
          }
        }
      }
    }
  }

  .menu-button-image {
    background-image: url("../../public/img/menu_button.png");
    width: 42px;
    height: 8px;
  }

  .mobile-menu {
    width: 60%;
    left:100%;
    position: relative;
    float: right;
    visibility: hidden;
    height: 0px;
    overflow: hidden;
    transition: 0.5s;
    -webkit-transition: 0.5s;
  }

  .mobile-menu.visible {
    left:0%;
    visibility: visible;
    height: 503px;
  }

  .text-expand {
    visibility: hidden;
    opacity:0;
    max-height: 0px;
    transition: all 0.5s;
    -webkit-transition: 0.5s;
  }

  .text-expand.visible {
    opacity:1;
    max-height: 300px;
  }

  .underlay {
    box-shadow: -30px 30px 0px 0px rgba(109, 140, 143, 0.5);
  }


  /* </Layout> */
  /* <Title> */
  .title {
    h1 {
      @include header-h1();
    }
    min-height: 600px;
  }

  /* </Title> */

  /* <About> */
  .plus {
    position: relative;
    width:30px;
    height:30px;

    &:before,
    &:after {
      content: "";
      position:absolute;
      background:#000;
    }

    &:before {
      left:50%;
      top:4px; /* this defines how much black "border" there should be */
      bottom:4px;
      width:2px;
      transition: .3s all;
      transform:translateX(-50%);
    }

    &:after {
      top:50%;
      left:4px;
      right:4px;
      height:2px;
      transform:translateY(-50%);
    }
  }
  .plus.open:before {
    transform:rotate(90deg);
    opacity: 0;
  }




  /* <Projects> */
  .project {
    &__text {
      margin-top: 15rem;
    }
    &__image-container {

    }
    &__image {

    }
  }

  //2 cols
  @media (min-width:780px) {
    .project {
      &__text {
        margin-top: 15rem;
      }  
      &__grid {
        @apply grid-cols-2;
      }
    }
  }

  @media (min-width:1000px) {
    .project {
      &__text {
        margin-top: 18rem;
      }  
      &__grid {
        @apply grid-cols-2;
      }
    }
  }

  //big 3cols
  @media (min-width:1200px) {
    .project {
      &__text {
        margin-top: 15rem;
      }    
      &__grid {
        @apply grid-cols-2;
      }
    }
  }

  //big 3cols
  @media (min-width:1400px) {
    .project {
      &__text {
        margin-top: 18rem;
      }    
      &__grid {
        @apply grid-cols-3;
      }
    }
  }
  

  //small
  /* </Projects> */

  /* </About> */

  /* <AboutUS> */

  .pic-office {
    background-image: url("../../public/img/office0.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -66px;
  }

  .demo-slide {
    min-width: 300px;
    min-height: 100px;
  }

  .carousel.carousel-slider {
    overflow: visible !important;
  }
  .control-dots {
   bottom: -40px !important;
  }
  #car-lg *,
  #car-sm * {
    pointer-events: none;
  }

  .carousel img {
    display: none
  }
  .carousel img.active {
    display: block
  }
  /* </AboutUS> */


  /* <Career> */

  .hh-button {
    padding-top: .35rem;
    padding-bottom: .35rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-left: 1rem;
  }

  @font-face {
    font-family: 'Nova Script';
    src: url('./fonts/NovaScript-Regular.ttf') format('truetype');
  }

  .number {
    font-family: 'Nova Script', 'truetype';
    font-size: 5rem;
    font-weight: 400;
  }

  /* </Career> */
  

}
